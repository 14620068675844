<template>
    <div class="profile-item">
        <p class="profile-item-header">{{ item.header }}</p>
        <div class="profile-item-container">
            <label class="profile-item-label">{{ item.subtext }}</label>
            <input v-if="item.id !== 3" class="profile-item-input" :type="item.inputType" :value="item.inputValue" readonly>
            <div class="input-with-eye" v-if="item.id === 3" >
                <input class="profile-item-input" :type="isPasswordShow ? 'text' : 'password'" :value="item.inputValue" readonly>
                <img v-if="!isPasswordShow" @click="toggleShowPassword" class="eye-img" src="@/components/assets/eye.svg"
                    alt="">
                <img v-else class="eye-img" @click="toggleShowPassword" src="@/components/assets/close-eye.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: Object
    },
    data() {
        return {
            isPasswordShow: false
        }
    },
    methods: {
        toggleShowPassword() {
            this.isPasswordShow = !this.isPasswordShow
        }
    }
}
</script>

<style scoped>
h1,
p,
button,
input,
label,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.profile-item {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.profile-item:nth-child(4) {
    border: none;
    padding-bottom: 0;
}

.profile-item-header {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
}

.profile-item-label {
    font-size: 13px;
    margin-bottom: 12px;
}

.profile-item-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 70%;
}

.profile-item-input {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    font-weight: 400;
    border-radius: 8px;
    padding: 12px 16px;
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.input-with-eye {
    position: relative;
}
.eye-img {
    position: absolute;
    top: 34%;
    right: 3%;
    cursor: pointer;
}

@media only screen and (max-width: 720px) {
    .profile-item {
        flex-direction: column;
    }

    .profile-item-container {
        width: 100%;
    }

    .profile-item-header {
        margin-bottom: 12px;
    }
}
</style>